import React, { PropsWithChildren } from 'react';
import loginImage from '@Assets/images/header-logo-ultima-2x.png';

export default function Guest({ children }: PropsWithChildren) {
  return (
    <div className="pages-body login-page flex flex-column">
      <div className="topbar p-3 flex justify-content-between flex-row align-items-center">
        <div className="topbar-left ml-3 flex">
          <div className="logo">
            <img src={loginImage} alt="" />
          </div>
        </div>
        <div className="topbar-right mr-3 flex">
          {/* <Link href={route('home')} as="button" type="button" className="p-button-text mr-2 mb-2">DASHBOARD</Link> */}
        </div>
      </div>
      {children}
    </div>
  );
}
