import React, { FormEventHandler } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import Guest from '@App/Layouts/GuestLayout';
import { InputLabel } from '@Components/InputLabel';
import { Checkbox } from 'primereact/checkbox';
import { useForm } from '@inertiajs/react';

function Login({ status, canResetPassword }: { status?: string; canResetPassword: boolean }) {
  const { data, setData, post, processing, errors, reset } = useForm({
    username: '',
    password: '',
    remember: false,
  });

  const submit: FormEventHandler = (e) => {
    e.preventDefault();

    post(route('login'));
  };

  return (
    <Guest>
      <div className="align-self-center mt-auto mb-auto">
        <form onSubmit={submit}>
          <div className="pages-panel card flex flex-column">
            <div className="pages-header px-3 py-1">
              <h2>LOGIN</h2>
            </div>

            <h4>Welcome</h4>
            <h3>
              {errors.username && (
                <small data-dusk="loginErrorMessage" className="p-error">
                  {errors.username}
                </small>
              )}
            </h3>

            <div className="pages-detail mb-6 px-6">Please use the credentials to login in jms</div>
            <div className="input-panel flex flex-column px-3">
              <div className="p-inputgroup">
                <span className="p-inputgroup-addon">
                  <i className="pi pi-envelope" />
                </span>
                <span className="p-float-label">
                  <InputText
                    type="text"
                    data-dusk="username"
                    id="username"
                    name="username"
                    value={data.username}
                    onChange={(e) => setData('username', e.target.value)}
                  />
                  <InputLabel htmlFor="username">Username</InputLabel>
                </span>
              </div>

              <div className="p-inputgroup mt-3 mb-6">
                <span className="p-inputgroup-addon">
                  <i className="pi pi-lock" />
                </span>

                <span className="p-float-label">
                  <InputText
                    type="password"
                    data-dusk="password"
                    id="password"
                    name="password"
                    value={data.password}
                    onChange={(e) => setData('password', e.target.value)}
                  />
                  <InputLabel htmlFor="password">Password</InputLabel>
                </span>
              </div>

              <div className="p-inputgroup mb-3">
                <div className="field-checkbox">
                  <Checkbox
                    inputId="rememberMe"
                    data-dusk="remember"
                    name="remember"
                    checked={data.remember}
                    onChange={(e) => {
                      setData('remember', e.checked || false);
                    }}
                  />
                  <InputLabel htmlFor="rememberMe">Remember me</InputLabel>
                </div>
              </div>
            </div>

            <Button className="login-button mb-6 px-3" data-dusk="login-button" label="LOGIN" />
          </div>
        </form>
      </div>
    </Guest>
  );
}

export default Login;
